import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import InformationLayout from "../../layouts/InformationLayout.jsx";
import indexData from "./index.json";
import Downloads from "../../components/_ui/Downloads.jsx";
export const _frontmatter = {
  "title": "Pergola Assembly - Attaching the Main Beam(s) to the Posts"
};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};

const MDXLayout = ({
  children,
  pagesList
}) => <InformationLayout pagesList={indexData.pageIndex}>
    {children}
  </InformationLayout>;

export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1>{`Step 4. Attaching the Main Beam(s) to the Posts`}</h1>
    <p>{`The next job to tackle is attaching the main beam to the posts. Line up the pre-drilled holes on the posts and the main beams and thread the coach bolts through. If it seems a bit tight, gently tap the bolt through with a non-metal mallet to prevent damage to the silver dome head then fix with the nut and washer provided and tighten. Do not forget to put the washer on as this will help prevent the nut from damaging the wood and make it easier to undo in the future. For best results, push the coach bolt through leaving the head visible on the outside of the post and the bulky nut on the inside (See Fig 14 & 15 below).`}</p>
    <div className="captioned">
  <img src="/img/pergola-assembly/step-4-attaching-the-main-beams-to-the-posts/fig14.jpg" alt="A diagram showing a front view of a 9ft x 9ft module, with the main beam attached to the posts via coach bolts." />
  <span className="text-metadata">Fig 14</span>
    </div>
    <div className="captioned">
  <img src="/img/pergola-assembly/step-4-attaching-the-main-beams-to-the-posts/fig15.jpg" alt="A side-view diagram displaying how to attach the main beam to a post with a coach bolt and washer" />
  <span className="text-metadata">Fig 15</span>
    </div>
    <p>{`If you have ordered either the 9ft x 16ft or 9ft x 23ft pergola modules, you will need to join together two main beams (for the 9ft x 16ft module) or two main beams plus the central extension board (for the 9ft x 23ft module) using the supplied 3–Way ('T' shape) fixing plate as illustrated in Fig16 & Fig 17 below. These longer modules have been design in such a way that the join will fall in the centre of the 'notched' post, so before fixing in place with the 3-Way fixing plate, check that there is no gap between the boards. These central post(s) do not have the coach bolt, washer and nut fixings as these are only required on the outer posts.`}</p>
    <div className="captioned">
  <img src="/img/pergola-assembly/step-4-attaching-the-main-beams-to-the-posts/fig16.jpg" alt="A hand-drawn diagram demonstrating how to fit the main beam to the to the post for 9ft x 16ft and 9ft x 23ft pergola modules, using a 3 way fixing bracket." />
  <span className="text-metadata">Fig 16</span>
    </div>
    <div className="captioned">
  <img src="/img/pergola-assembly/step-4-attaching-the-main-beams-to-the-posts/fig17.jpg" alt="A hand-drawn diagram demonstrating a birds-eye view of the main beam to post attachment displayed in fig 16." />
  <span className="text-metadata">Fig 17</span>
    </div>
    <p><em parentName="p">{`(`}{`*`}{`NB these drawings are for representation purposes only and not to scale)`}</em></p>
    <h2>{`Downloads & Support`}</h2>
    <Downloads files={[{
      filename: "Pergola assembly guide",
      path: "/downloads/ASSEMBLY.pdf"
    }]} mdxType="Downloads" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      